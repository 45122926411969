import { isFinite, isString } from "lodash-es";
import { INode, IProject } from "src/store/projects/types";

const utils = {
    /**
     * Format the number to two decimals after the comma
     */
    formatNumber: (value: number, precision = 2) => {
        if (value === null) {
            return "-";
        }

        return Math.round(value * 10 ** precision) / 10 ** precision;
    },
    /**
     * Format the given number to two decimals after the comma and optionally add a unit.
     * If above max, it will be shown as value+
     * @returns {string} A space if value is undefined, otherwise the number with two decimals after the comma and optionally a unit
     */
    formatNumberUnit: (
        value: string | number | undefined,
        unit?: string,
        factor?: number,
        precision = 2,
        max?: number
    ) => {
        const origValue = value;
        if (isString(value)) {
            value = parseFloat(value);
        }

        if (value !== undefined && isFinite(value)) {
            let result = "";

            if (factor !== undefined) {
                value *= factor;
            }

            let num = utils.formatNumber(value, precision);

            if (max !== undefined) {
                if (num > max) {
                    num = max;
                    result += num;
                    result += "+";
                } else {
                    result += num;
                }
            } else {
                result += num;
            }

            if (isString(origValue) && origValue.endsWith(".")) {
                result += ".";
            }

            if (isString(origValue) && origValue.endsWith(",")) {
                result += ",";
            }

            if (unit !== undefined) {
                result += " " + unit;
            }

            return result;
        } else {
            return " ";
        }
    },
    /**
     * The inverse operation of formatNumber unit to get back a number
     */
    unFormatNumberUnit: (value: string | number | undefined, unit?: string, factor?: number) => {
        let strValue = "" + (value || 0);
        if (unit) {
            strValue = strValue.replace(" " + unit, "");
        }

        let nrValue = parseFloat(strValue);
        if (nrValue !== undefined && isFinite(nrValue)) {
            if (factor !== undefined) {
                nrValue /= factor;
            }

            if (isString(value) && (value.endsWith(".") || strValue.startsWith(".0"))) {
                return nrValue + ".";
            }

            if (isString(value) && (value.endsWith(",") || strValue.startsWith(",0"))) {
                return nrValue + ",";
            }

            return nrValue;
        } else {
            return 0;
        }
    },
    /**
     * Output the given unit if the value is defined otherwise the empty string
     */
    formatUnit: (value: number | undefined, unit: string) => {
        if (value !== undefined && isFinite(value)) {
            return unit;
        } else {
            return "";
        }
    },
    /**
     * Copy the string to the clipboard
     * @param str - The HTML string
     */
    copyToClip(str: string) {
        function listener(e: any) {
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
    },

    renderCalculatedLifetimeExpectancy: (node: INode, project?: IProject, dashCharacter = "-") => {
        if (
            node.carbonationCalculationResultMessage &&
            node.carbonationCalculationResultMessage === "NO_FAILURE" &&
            project
        ) {
            return (project.maxCalculationAge || 100) + "+";
        } else if (node.calculatedLifetimeExpectancy !== null && node.calculatedLifetimeExpectancy !== undefined) {
            return utils.formatNumberUnit(
                node.calculatedLifetimeExpectancy,
                undefined,
                undefined,
                0,
                project && project.maxCalculationAge
            );
        } else {
            return dashCharacter;
        }
    },

    renderCalculatedRemainingLifetimeExpectancy: (node: INode, project?: IProject, dashCharacter = "-") => {
        if (
            node.carbonationCalculationResultMessage &&
            node.carbonationCalculationResultMessage === "NO_FAILURE" &&
            project &&
            node.propagatedAge
        ) {
            return (project.maxCalculationAge || 100) - node.propagatedAge.value + "+";
        } else if (
            node.calculatedLifetimeExpectancy !== null &&
            node.calculatedLifetimeExpectancy !== undefined &&
            node.propagatedAge
        ) {
            return utils.formatNumberUnit(
                node.calculatedLifetimeExpectancy - node.propagatedAge.value,
                undefined,
                undefined,
                0,
                project && project.maxCalculationAge && project.maxCalculationAge - project.age
            );
        } else {
            return dashCharacter;
        }
    },
    renderCalculatedChlorideLifetimeExpectancy: (node: INode, project?: IProject, dashCharacter = "-") => {
        if (
            node.chlorideCalculationResultMessage &&
            node.chlorideCalculationResultMessage === "NO_FAILURE" &&
            project
        ) {
            return (project.maxCalculationAge || 100) + "+";
        } else if (
            node.calculatedChlorideLifetimeExpectancy === null ||
            node.calculatedChlorideLifetimeExpectancy === undefined ||
            node.calculatedChlorideLifetimeExpectancy === -1
        ) {
            // - if D not accepted (= -1) or not defined
            return dashCharacter;
        } else {
            return utils.formatNumberUnit(
                node.calculatedChlorideLifetimeExpectancy,
                undefined,
                undefined,
                0,
                project && project.maxCalculationAge
            );
        }
    },
    renderCalculatedRemainingChlorideLifetimeExpectancy: (node: INode, project?: IProject, dashCharacter = "-") => {
        if (
            node.chlorideCalculationResultMessage &&
            node.chlorideCalculationResultMessage === "NO_FAILURE" &&
            project &&
            node.propagatedAge
        ) {
            return (project.maxCalculationAge || 100) - node.propagatedAge.value + "+";
        } else if (
            node.calculatedChlorideLifetimeExpectancy === null ||
            node.calculatedChlorideLifetimeExpectancy === undefined ||
            node.calculatedChlorideLifetimeExpectancy === -1
        ) {
            // - if D not accepted (= -1) or not defined
            return dashCharacter;
        } else if (
            node.calculatedChlorideLifetimeExpectancy !== null &&
            node.calculatedChlorideLifetimeExpectancy !== undefined &&
            node.propagatedAge
        ) {
            return utils.formatNumber(node.calculatedChlorideLifetimeExpectancy - node.propagatedAge.value, 0);
        } else {
            return dashCharacter;
        }
    },
    isDecimal: (value: number) => {
        return value % 1 !== 0;
    },
    isDefined: (value: number | null | undefined) => {
        return value !== null && value !== undefined;
    },
    getNodeError(node: INode): { type: "error" | "warning"; message: string } | null {
        if (node.nodeErrorMsg) {
            // ugly dependency on backend error messages
            if (node.nodeErrorMsg.indexOf("are there any covermeasurments set") > -1) {
                return {
                    type: "warning",
                    message: "This node could not be calculated because there are no concrete cover measurements found."
                };
            } else {
                return {
                    type: "error",
                    message: node.nodeErrorMsg
                };
            }
        }

        return null;
    },
    getDownloadURL(path?: string) {
        if (!path) {
            return undefined;
        }
        const anyWindow = window as any;
        // __runtime_configuration should always be there in aws
        const environment = anyWindow.__runtime_configuration
            ? anyWindow.__runtime_configuration.environment
            : process.env.ENVIRONMENT;

        return `https://dimcost-${environment}-public.s3.eu-central-1.amazonaws.com/${path}`;
    }
};

// same image as other tables (see ant design table empty code)
export const emptyImg =
    "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNjRweCIgaGVpZ2h0PSI0MXB4IiB2aWV3Qm9" +
    "4PSIwIDAgNjQgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5" +
    "OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjUgKDY3NDY5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgI" +
    "DxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYX" +
    "RlKC00NzIuMDAwMDAwLCAtMTMzNS4wMDAwMDApIj4KICAgICAgICAgICAgPGcgIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY0LjAwMDAwMCwgMTExNC4wMDAwMDApIj4KICA" +
    "gICAgICAgICAgICAgIDxnICB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0MC4wMDAwMDAsIDc4LjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxnICB0cmFuc2Zvcm09" +
    "InRyYW5zbGF0ZSgzNjguMDAwMDAwLCAxNDQuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDxnID4KICAgICAgICAgICAgICAgICAgICAgICAgICAgIDxlb" +
    "GxpcHNlICBmaWxsPSIjRjVGNUY1IiBjeD0iMzIiIGN5PSIzMyIgcng9IjMyIiByeT0iNyI+PC9lbGxpcHNlPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPGcgIC" +
    "B0cmFuc2Zvcm09InRyYW5zbGF0ZSg5LjAwMDAwMCwgMC4wMDAwMDApIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0Q5RDlEOSI+CiAgICAgICAgICAgICAgICA" +
    "gICAgICAgICAgICAgICAgPHBhdGggZD0iTTQ2LDEyLjc2MDU2MDQgTDM1Ljg1NDMwNDcsMS4yNTczOTYzMyBDMzUuMzY3NDQxNCwwLjQ3MzgyNjYwNSAzNC42NTU4Nzg5" +
    "LDAgMzMuOTA2NzYxNywwIEwxMi4wOTMyMzgzLDAgQzExLjM0NDEyMTEsMCAxMC42MzI1NTg2LDAuNDczOTUwMjU1IDEwLjE0NTY5NTMsMS4yNTczOTYzMyBMMi42MTQ3O" +
    "TcyN2UtMTIsMTIuNzYwNTYwNCBMMCwyMiBMNDYsMjIgTDQ2LDEyLjc2MDU2MDQgWiIgID48L3BhdGg+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgPHBhdG" +
    "ggZD0iTTMyLjYxMzI4MTMsMTUuOTMxNSBDMzIuNjEzMjgxMywxNC4zMjU4NTExIDMzLjYwNjk1MzEsMTMuMDAwMjM0IDM0LjgzOTY5OTIsMTMgTDQ2LDEzIEw0NiwzMS4" +
    "xMzcxMjc3IEM0NiwzMy4yNTg5NTc0IDQ0LjY3OTM4NjcsMzUgNDMuMDUwNDI5NywzNSBMMi45NDk1NzAzMSwzNSBDMS4zMjA1MjM0NCwzNSAwLDMzLjI1ODg0MDQgMCwz" +
    "MS4xMzcxMjc3IEwwLDEzIEwxMS4xNjAzMDA4LDEzIEMxMi4zOTMwNDY5LDEzIDEzLjM4NjcxODgsMTQuMzIyODA4NSAxMy4zODY3MTg4LDE1LjkyODQ1NzQgTDEzLjM4N" +
    "jcxODgsMTUuOTQ5NjM4MyBDMTMuMzg2NzE4OCwxNy41NTUyODcyIDE0LjM5MTcxMDksMTguODUxMTgwOSAxNS42MjQ0NTcsMTguODUxMTgwOSBMMzAuMzc1NTQzLDE4Lj" +
    "g1MTE4MDkgQzMxLjYwODI4OTEsMTguODUxMTgwOSAzMi42MTMyODEzLDE3LjU0MzM1MTEgMzIuNjEzMjgxMywxNS45Mzc3MDIxIEwzMi42MTMyODEzLDE1LjkzMTUgWiI" +
    "gIGZpbGw9IiNGQUZBRkEiPjwvcGF0aD4KICAgICAgICAgICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAg" +
    "ICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+";

export default utils;
